import React from "react";
import logo from '../../assets/images/logo.png';

import Signature from './Signature';

export default function FooterPage() {
    return (
        <div>
            <footer className='footer'>
                <a href='tel:6782312922' className='contact-link'>
                    <i className="bi bi-telephone-fill"></i> 678-231-2922
                </a>
                <a href='mailto:kellinoelle@gmail.com' className='contact-link'>
                    <i className="bi bi-envelope-fill"></i> kellinoelle@gmail.com
                </a>
                <p>
                <i className="bi bi-building-fill"></i> 1683 Austin Dr, Decatur, GA 30032
                </p>
                <p><b>Socials</b></p>
                <p><a href="https://www.instagram.com/leashmeout/" target="_blank" rel="noreferrer"><i class="fab fa-instagram"></i></a></p>
                <p>Copyright © Leash Me Out LLC. All Rights Reserved {new Date().getFullYear()}</p>
                <div className='logo'>
                    <a href="/#home"><img src={logo} alt="Leash Me Out"></img></a>
                </div>

                <Signature />

            </footer>
    </div>
    )
};
