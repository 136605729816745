import React from 'react';
// import image from '../../assets/images/grass-trees-2304w.webp';
// import image from '../../assets/images/IMG_1927.jpg';
import image from '../../assets/images/georgia-profile-pic.png';
// import image from '../../assets/images/71124377633__CE1FCF33-A1B9-452F-B8C9-B880E80BC885.mov';
// import image from '../../assets/images/70784152145__33E60A9A-28DD-440D-898C-157212743443.jpg'
import 'reactjs-popup/dist/index.css';

import { Link } from 'react-router-dom';

export default function Homepage() {
  return (
    <div className='homepageBackgroundImage' style={{
      backgroundImage:`url(${image})`,
      backgroundRepeat: 'no-repeat', 
      backgroundSize:'cover', 
      padding: '18.5%',
    }}>
      <div className='homePageContent'>
        <i className="bi bi-geo-alt-fill"></i>
        <h3>Based in Atlanta, GA</h3>
        <p>We have proudly served our clients' pets for 10+ years</p>
        <Link to="/booknow">
          <button className="purchase-button-homepage">
            <b>Book Now</b>
          </button>
        </Link>
        {/* <a href="/booknow" rel="noopener noreferrer">
          <button className="purchase-button-homepage">
            <b>Book Now</b>
          </button>
        </a> */}
        <hr></hr>
        <p><a href="https://www.instagram.com/leashmeout/" target="_blank" rel="noreferrer"><i class="fab fa-instagram"></i></a></p>
      </div>
    </div>
  );
}