import React from "react";
// import Carousel from "react-bootstrap/Carousel";
// import solarPanelKitImage from '../../assets/images/solarPanelKit.png';

// NOTES: use barn as icons for the green cards

export default function Services() {

  const servicesList = [
    {
      id: 1,
      serviceType: 'Doggy Daycare',
      serviceDescription: 'Drop your pet off for the day!',
      priceTag: '$35 per day',
      cardImage: require('../../assets/images/dog-paw.png')
    },
    {
      id: 2,
      serviceType: 'Dog Boarding',
      serviceDescription: 'Board your dog for the day!',
      priceTag: '$50 per day',
      cardImage: require('../../assets/images/barn.png')
    },
    {
      id: 3,
      serviceType: 'Pet Sitting',
      serviceDescription: 'We will sit cats, chickens, birds',
      priceTag: '$25 per visit, & additional fee per extra pet',
      cardImage: require('../../assets/images/georgia-profile-pic.png')
    },
  ]

  return (
    <div>
      <h1>Services</h1>     
      <div className="services-page">
        {servicesList.map((data) =>
          <div key={data.id} className="card">
            <img
              src={data.cardImage}
              alt="Card Thumbnail"
              className="card-thumbnail"
            />
    
            <div className="card-content">
              <h3>{data.serviceType}</h3>
              <p>{data.serviceDescription}</p>
              <div className="price-tag">{data.priceTag}</div>
              <a href="/booknow" rel="noopener noreferrer">
                <button className="purchase-button">
                  <b>Book Now</b>
                </button>
              </a>
            </div>
          </div>
        )}
      </div>

      <div className="services-page-notice">
        <hr id='services-hr'></hr>
        <p>Please note the following:</p>
        <ul>
          <li>$5 transportation fee for pickup/drop-off</li>
          <li>All dogs need to be fully vaccinated 10 days before boarding.</li>
          <li>All dogs need to be spayed/neutered; with exception for puppies.</li>
          <li>We do not accept pitbull breeds.</li>
          <li>We require records of vaccination history</li>
          <li>All dogs need to be fully vaccinated 10 days before boarding.</li>
          <li>All dogs need to be spayed/neutered; with exception for puppies.</li>
          <li>We do not accept pitbull breeds.</li>
        </ul>
      </div>
    </div>
  )
}