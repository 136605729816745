import React, { useState } from "react";
// import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.png';

import Popup from './pages/Popup';

export default function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleToggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

    return (
        <div className="App-header">
          <Popup />
        <nav className={`nav-bar ${isNavOpen ? 'active' : ''}`}>
        {/* <nav> */}
          <div className='top-row'>
            <div className='contact-links'>
              <a href='tel:6782312922' className='contact-link'>
                <i className="bi bi-telephone-fill"></i> 678-231-2922
              </a>
              <a href='mailto:kellinoelle@gmail.com' className='contact-link'>
                <i className="bi bi-envelope-fill"></i> kellinoelle@gmail.com
              </a>
            </div>
            <div className='nav-buttons'>
              <a href="https://www.timetopet.com/portal/leash-me-out/create-account" target="_blank" rel="noopener noreferrer">
                <button className="nav-button">
                <i className="bi bi-unlock-fill"></i> <b>New Clients</b>
                </button>
              </a>
              <a href="https://www.timetopet.com/portal/leash-me-out" target="_blank" rel="noopener noreferrer">
                <button className="nav-button">
                <i className="bi bi-lock-fill"></i> <b>Returning Clients</b>
                </button>
              </a>
            </div>
          </div>

          <div className='nav-header'>
            <h1 className='nav-title'>
              <div className='logo'>
                {/* <a href="/#home">Leash Me Out</a> */}

                <a href="/#home">
                  <img src={logo} alt="Leash Me Out"></img>
                </a>

              </div>
            </h1>

            <ul className={`nav-list ${isNavOpen ? 'active' : ''}`}>
              <li>
                <a href="/#home">Home</a>
              </li>
              <li>
                <a href='/#services'>Services</a>
              </li>
              <li>
                <a href='/#about'>About</a>
                {/* <Link to="/#about">About</Link> */}
              </li>
              <li>
                <a href='/#contact'>Contact</a>
              </li>
            </ul>

            <div className={`hamburger ${isNavOpen ? 'active' : ''}`} onClick={handleToggleNav}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>

          </div>

        </nav>

      </div>
    )
}