import React from 'react';
import { useState } from 'react';

export default function Popup() {

  const [showPopup, setShowPopup] = useState(true);
  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <div>
    {showPopup && (
    <div className='popup'>
        <div className='popup-content'>
            <h2>Weclome to Leash Me Out!</h2>
            <p>Please consider the following:</p>
            <ul>
                <li>Holiday fees apply — 4 day minimum for holidays.</li>
            </ul>
            {/* <p>All dogs need to be fully vaccinated 10 days before boarding.</p>
            <p>All dogs need to be spayed/neutered; with excdption for puppies.</p>
            <p>We do not accept pitbull breeds.</p> */}
            <button onClick={handleClose}>Close</button>
        </div>
    </div>
)}
</div>
  );
}